import { navigate } from 'gatsby';
import React from 'react';

class Component extends React.Component {
  componentDidMount() {
    navigate('/about');
  }

  render() {
    return null;
  }
}

export default Component;
